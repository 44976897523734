


















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
    KomisAccordion,
    KomisBox,
    KomisButton,
    KomisCheckbox,
    KomisContentHeader,
    KomisDateField,
    KomisHeading5,
    KomisSelect,
    KomisTextArea,
    KomisTextField,
    KomisUploadFiles,
} from "@iway/spa-components";

import { IFile, moduleName, postFile, postFileToDms, product } from "@/api/documents.api";
import { getAttachmentTypes, IAttachmentType } from "@/api/stimulus.api";
import { RouteNames } from "@/router";

const defaultPublicState = true;

const initialFileReference = (moduleId: string, isPublic = defaultPublicState) => ({
    isPublic,
    fileKey: "",
    metadata: {
        attachmentType: null,
        nameSk: "",
        nameEn: "",
        author: "",
        coauthor: "",
        lang: "sk",
        keywordsSk: "",
        keywordsEn: "",
        copyrightSk: "",
        copyrightEn: "",
        sourceSk: "",
        sourceEn: "",
    },
    parentId: null,
    moduleId,
    product: product,
    module: moduleName,
});

@Component({
    components: {
        KomisUploadFiles,
        KomisButton,
        KomisBox,
        KomisContentHeader,
        KomisDateField,
        KomisTextField,
        KomisTextArea,
        KomisSelect,
        KomisHeading5,
        KomisCheckbox,
        KomisAccordion,
    },
})
export default class UploadDocument extends Vue {
    @Prop({ type: [Boolean, String], default: undefined }) isPublic: boolean | undefined;

    get forcePublicState() {
        return typeof this.isPublic === "boolean";
    }

    file: File | null = null;
    loading = false;
    valid = true;

    maxLength = 100;
    fileReference: IFile = initialFileReference(this.$route.params.id);

    attachmentTypes: IAttachmentType[] = [];
    attachmentTypesLoading = false;

    get isEvaluation(): boolean {
        return (
            this.$route.name === RouteNames.EvaluationsDocuments || this.$route.name === RouteNames.StimulusEvaluationsDocuments
        );
    }

    created() {
        this.attachmentTypesLoading = true;
        getAttachmentTypes()
            .then((types) => (this.attachmentTypes = types))
            .finally(() => (this.attachmentTypesLoading = false));
    }
    mounted() {
        if (this.forcePublicState) {
            this.fileReference = initialFileReference(
                this.isEvaluation ? this.$route.params.evaluationId : this.$route.params.id,
                this.isPublic,
            );
        }
    }

    onFileChange(file: File) {
        this.file = file;
        console.log("onFileChange", this.file);
    }

    uploadFiles() {
        if (this.file && this.valid) {
            let formData = new FormData();
            formData.append("file", this.file, this.file.name);

            // additional data
            //formData.append("test", "foo bar");

            postFileToDms(formData).then((response) => {
                console.log("XHR Upload: ", response);
                if (response.success) {
                    response.key.forEach((item: string) => {
                        this.fileReference.fileKey = item;
                        this.postFileWithReference(this.fileReference);
                    });
                }
            });
        } else {
            console.error("there are no files or there are validation errors.");
        }
    }

    postFileWithReference(file: IFile) {
        this.loading = true;
        return postFile(file)
            .then((response) => {
                console.log(response);
                if (response.success) {
                    console.log("file upload with meta - success", response.data.name);
                    this.$emit("file-uploaded", response.data);
                    this.fileReference = initialFileReference(
                        this.$route.params.id,
                        this.forcePublicState ? this.isPublic : defaultPublicState,
                    );
                    this.file = null;
                    (this.$refs.form as any).resetValidation();
                }
            })
            .finally(() => (this.loading = false));
    }
}































import { Component } from "vue-property-decorator";
import { KomisButton, KomisContentHeader } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import StimulusTable from "@/components/stimulus-list/StimulusTable.vue";
import ProjectsList from "@/components/projects/ProjectsList.vue";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import { EStimulusStateCodes, getStimulusState, IStimulusState } from "@/api/stimulus.api";
import { Location } from "vue-router";
import { userIsOrganizationAdmin } from "@/api/organization.api";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import { isOriginFromPersonalSpace } from "@/mixins/Utils";

@Component({
    components: { ProjectsList, StimulusTable, KomisContentHeader, KomisButton },
})
export default class ProjectsListView extends BaseView {
    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusProjectsList;
    }
    get stimulusId() {
        return this.$route.params.id;
    }
    get organizationId(): string | null {
        return this.$route.params.organizationId || null;
    }

    stimulusState: IStimulusState | null = null;
    isAdminOfOrganization: boolean | null = null;

    async created() {
        getStimulusState(this.stimulusId).then((stimulus) => (this.stimulusState = stimulus));

        if (this.organizationRoute && this.organizationId) {
            this.isAdminOfOrganization = await userIsOrganizationAdmin(this.organizationId).catch(() => false);
        }
    }

    get canEdit() {
        return this.isAdminOfOrganization && this.stimulusState?.code && this.isOriginFromPersonalSpace
            ? [EStimulusStateCodes.Rozpracovana, EStimulusStateCodes.VratenaNaDoplnenie].includes(this.stimulusState.code)
            : false;
    }

    get canCreateSKCRISProject(): boolean {
        return (
            !this.organizationId &&
            userHasRole(USER_ROLE.SpravcaAgendyStimulyVav) &&
            !!this.stimulusState?.code &&
            [EStimulusStateCodes.Schvalena, EStimulusStateCodes.SchvalenaNefinancovana].includes(this.stimulusState?.code)
        );
    }

    get isOriginFromPersonalSpace(): boolean {
        return isOriginFromPersonalSpace();
    }

    mounted() {
        if (this.organizationRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: {
                    name: RouteNames.OrganizationStimulusList,
                    params: { id: this.$route.params.organizationId },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.projects_list").toString(),
                to: {
                    name: RouteNames.OrganizationStimulusProjectsList,
                    params: { organizationId: this.$route.params.organizationId, id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list").toString(),
                to: { name: RouteNames.StimulusList },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("projects.list").toString(),
                to: { name: RouteNames.ProjectsList, params: { id: this.$route.params.id } },
                disabled: false,
                link: true,
                exact: true,
            });
        }
    }

    get backButtonRoute(): Location {
        if (this.organizationRoute) {
            return {
                name: RouteNames.OrganizationStimulusDetail,
                params: { organizationId: this.$route.params.organizationId, id: this.$route.params.id },
            };
        } else {
            return { name: RouteNames.StimulusDetail, params: { id: this.$route.params.id } };
        }
    }

    get newProjectLink(): string | false {
        return getNetgrifUrlByParams(
            "vytvorenie_grant_projektu",
            "SKCRIS-033",
            { orgId: this.$route.params.organizationId, grantId: this.$route.params.id },
            this.$store.state.language.value,
        );
    }
}

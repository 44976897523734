import { castArray } from "lodash";
import store from "@/store";
import { Route } from "vue-router";
import { RouteNames } from "@/router";
import { userIsOrganizationAdmin } from "@/api/organization.api";

export enum USER_ROLE {
    RegistrovanyPouzivatel = "SKCRIS_registrovany_pouzivatel",
    ClenKomisieStimulyVav = "SKCRIS_clen_komisie_stimuly_vav",
    SpravcaAgendyStimulyVav = "SKCRIS_spravca_agendy_stimuly_vav",
    Hodnotitel = "SKCRIS_hodnotitel",
    SpravcaRegistrov = "SKCRIS_spravca_registrov",
    SpravcaRegistraOrganizacie = "SKCRIS_spravca_registra_organizacie",
}

export enum VALIDATION_TYPE {
    OneOf = "oneOf",
    AllOf = "allOf",
}

/*
USAGE:
    meta: { role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav] },
 */
export const userHasRole = (role: USER_ROLE | USER_ROLE[], validationState?: VALIDATION_TYPE): boolean => {
    role = castArray(role);
    const userRoles = store.state.loggedUser.roles || [];
    if (validationState === VALIDATION_TYPE.OneOf) {
        return role.some((value) => userRoles.includes(value));
    } else {
        return role.every((value) => userRoles.includes(value));
    }
};

export const checkRole = async (to: Route): Promise<boolean> => {
    const Route403 = RouteNames.Error403;

    // wait for user loaded
    if (!store.state.userLoaded) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        return checkRole(to);
    }

    if (to.name !== Route403 && to.meta?.role && !userHasRole(to.meta.role, to.meta.roleValidationType || undefined)) {
        return Promise.resolve(false);
    }

    return Promise.resolve(true);
};

/*
    USAGE:
    meta: { isOrganizationAdmin: true, routeIdParamName: 'organizationId' }
 */
export const checkIsAdminOfOrganization = async (to: Route): Promise<boolean | null> => {
    //const Route403 = RouteNames.Error403;
    if (!to.meta?.isOrganizationAdmin) {
        return Promise.resolve(null);
    }

    // wait for envParams loaded
    if (!store.state.envParams) {
        await new Promise((resolve) => setTimeout(resolve, 100));
        return checkIsAdminOfOrganization(to);
    }

    // check if user is organization admin
    const paramName: string = to.meta.routeParamName || "id";
    const id = to.params[paramName];

    return await userIsOrganizationAdmin(id).catch(() => false);
};




































import { Component } from "vue-property-decorator";
import { KomisButton, KomisContentHeader, KomisDialog, KomisKeyValueList } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import StimulusTable from "@/components/stimulus-list/StimulusTable.vue";
import UploadDocument from "@/components/documents/UploadDocument.vue";
import DocumentsTable from "@/components/documents/DocumentsTable.vue";
import { loadDocumentDetail, stimulusDocument } from "@/components/documents/document-detail";
import { Location } from "vue-router";
import { deleteFile, generateDownloadLink } from "@/api/documents.api";
import { userIsOrganizationAdmin } from "@/api/organization.api";
import { isOriginFromPersonalSpace } from "@/mixins/Utils";

@Component({
    components: {
        DocumentsTable,
        UploadDocument,
        StimulusTable,
        KomisContentHeader,
        KomisButton,
        KomisKeyValueList,
        KomisDialog,
    },
})
export default class DocumentDetailView extends BaseView {
    isAdminOfOrganization: boolean | null = null;

    async created() {
        if (this.documentId) {
            loadDocumentDetail(this.documentId);
        }

        if (this.organizationRoute && this.organizationId) {
            this.isAdminOfOrganization = await userIsOrganizationAdmin(this.organizationId).catch(() => false);
        }
    }

    get isOriginFromPersonalSpace(): boolean {
        return isOriginFromPersonalSpace();
    }

    get document() {
        return stimulusDocument.detail;
    }
    get loading() {
        return stimulusDocument.loading;
    }

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationDocumentDetail;
    }

    get evaluationsRoute() {
        return this.$route.name === RouteNames.EvaluationsDocumentDetail;
    }

    get documentId(): number | false {
        return parseInt(this.$route.params.documentId) || false;
    }

    get stimulusId(): string {
        return this.$route.params.id;
    }

    get organizationId(): string | null {
        return this.$route.params.organizationId || null;
    }

    get pageTitle() {
        return this.document?.name || "-";
    }

    get detailInfoItems() {
        return [
            { key: this.$i18n.t("documents.datatable.name"), value: this.document?.name },
            { key: this.$i18n.t("documents.datatable.last_change"), value: this.document?.lastUpdate },
            { key: this.$i18n.t("documents.datatable.filetype"), value: this.document?.type.name },
            { key: this.$i18n.t("documents.datatable.size"), value: this.document?.size },
            {
                key: this.$i18n.t("documents.uploader"),
                value: `${this.document?.uploader.title} ${this.document?.uploader.firstName} ${this.document?.uploader.lastName}`,
            },
            {
                key: this.$i18n.t("actions.download"),
                value: this.document?.url
                    ? `<a href="${generateDownloadLink(this.document?.url)}">${this.document?.name}</a>`
                    : "-",
            },
        ];
    }

    mounted() {
        if (this.organizationRoute && this.organizationId) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: { name: RouteNames.OrganizationStimulusList, params: { id: this.organizationId } },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: {
                    name: RouteNames.OrganizationStimulusDetail,
                    params: { organizationId: this.organizationId, id: this.stimulusId },
                },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.documents_list").toString(),
                to: this.backButtonRoute,

                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.document_detail").toString(),
                to: {
                    name: RouteNames.OrganizationDocumentDetail,
                    params: { id: this.stimulusId, documentId: this.documentId.toString(), organizationId: this.organizationId },
                },
                disabled: false,
                link: true,
                exact: true,
            });
        } else if (this.evaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list").toString(),
                to: { name: RouteNames.AllEvaluations },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.documents").toString(),
                to: { name: RouteNames.Documents },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("documents.detail").toString(),
                to: {
                    name: RouteNames.DocumentDetail,

                    params: { id: this.$route.params.id, documentId: this.documentId.toString() },
                },
                disabled: false,
                link: true,
                exact: true,
            });
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list").toString(),
                to: { name: RouteNames.StimulusList },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: { name: RouteNames.StimulusDetail, params: { id: this.stimulusId } },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.documents").toString(),
                to: { name: RouteNames.Documents, params: { id: this.stimulusId } },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("documents.detail").toString(),
                to: {
                    name: RouteNames.DocumentDetail,

                    params: { id: this.$route.params.id, documentId: this.documentId.toString() },
                },
                disabled: false,
                link: true,
                exact: true,
            });
        }
    }

    get backButtonRoute(): Location {
        return this.organizationRoute && this.organizationId
            ? { name: RouteNames.OrganizationDocuments, params: { id: this.stimulusId, organizationId: this.organizationId } }
            : { name: RouteNames.Documents, params: { id: this.stimulusId } };
    }

    deleteDocument() {
        const documentId = this.document?.id;
        if (!documentId) {
            return;
        }

        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(
                this.$i18n.t("modals.confirm_delete.document", {
                    document: this.document?.name,
                }).toString(),
            )
            .then(() => {
                deleteFile(documentId).then(() => {
                    this.$router.push(this.backButtonRoute);
                });
            });
    }
}

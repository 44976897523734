












































































import { Component, Prop, Vue } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { IDatatableOptions } from "@/api/types";
import { KomisActionButton, KomisAlert, KomisButton, KomisDialog, KomisTable } from "@iway/spa-components";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";
import {
    deleteProject,
    EStimulusStateCodes,
    EStimulusProjectStateCodes,
    getProjectOfStimulus,
    IProjectsDatatableResponse,
    IStimulusProjectsApiItem,
    IStimulusState,
    postProjectToSkcris,
    setProjectState,
} from "@/api/stimulus.api";

import { USER_ROLE, userHasRole } from "@/helpers/user";

@Component({
    components: {
        KomisTable,
        KomisAlert,
        KomisActionButton,
        KomisDialog,
        KomisButton,
    },
})
export default class ProjectsTable extends Vue {
    @Prop({ required: true, type: String }) stimulusId!: string;
    @Prop(Object) stimulusState!: IStimulusState | null;
    @Prop(String) organizationAdmin!: boolean;
    @Prop(Boolean) hasPermission!: boolean;

    getNetgrifUrlByParams = getNetgrifUrlByParams;
    error = false;
    projectsDatatable: IDatatableOptions & { items: IStimulusProjectsApiItem[] } = {
        headers: [],
        limit: 10,
        count: 0,
        items: [],
        loading: false,

        options: {
            page: 1,
            itemsPerPage: 10,
            sortBy: [],
            sortDesc: [],
            groupBy: [],
            groupDesc: [],
            multiSort: true,
            mustSort: false,
        },
    };

    get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$i18n.t("projects.datatable.type") as string,
                sortable: true,
                value: "type.name",
            },
            {
                text: this.$i18n.t("projects.datatable.state") as string,
                sortable: true,
                value: "state.name",
            },
            {
                text: this.$i18n.t("projects.datatable.name") as string,
                sortable: true,
                value: "name",
            },
            {
                text: this.$i18n.t("projects.datatable.actions") as string,
                sortable: false,
                value: "actions",
            },
        ];
    }

    get canDelete() {
        return this.stimulusState?.code && this.hasPermission
            ? [EStimulusStateCodes.Rozpracovana, EStimulusStateCodes.VratenaNaDoplnenie].includes(this.stimulusState.code)
            : false;
    }

    canReopen(project: IStimulusProjectsApiItem) {
        console.log("org admin", this.organizationAdmin);
        return this.stimulusState?.code && project.state.code === EStimulusProjectStateCodes.Kompletny && this.organizationAdmin && this.hasPermission
            ? [EStimulusStateCodes.Rozpracovana, EStimulusStateCodes.VratenaNaDoplnenie].includes(this.stimulusState.code)
            : false;
    }

    canSubmitToSkcris(project: IStimulusProjectsApiItem) {
        return this.stimulusState?.code &&
            project.project.id &&
            project.state?.code === EStimulusProjectStateCodes.Kompletny &&
            this.hasPermission &&
            userHasRole(USER_ROLE.SpravcaAgendyStimulyVav)
            ? [EStimulusStateCodes.Schvalena, EStimulusStateCodes.SchvalenaNefinancovana].includes(this.stimulusState.code)
            : false;
    }

    get stimulusInProgress() {
        return this.stimulusState?.code && this.stimulusState.code === EStimulusStateCodes.Rozpracovana;
    }

    mounted(): void {
        this.loadProjects(this.projectsDatatable.options);

        this.projectsDatatable.headers = this.tableHeaders;
    }

    processDatatableResponse(datatableResponse: IProjectsDatatableResponse): void {
        this.projectsDatatable.items = datatableResponse.data;
        this.projectsDatatable.count = datatableResponse.count;
    }

    loadProjects(paginationOptions: DataOptions | undefined = undefined): void {
        this.projectsDatatable.loading = true;
        this.error = false;

        if (paginationOptions) {
            getProjectOfStimulus(this.stimulusId, paginationOptions)
                .then((datatableResponse) => {
                    this.processDatatableResponse(datatableResponse);
                })
                .catch((err: any) => {
                    console.error("loadProjects -> getProjects() error", err);
                    this.error = true;
                })
                .finally(() => (this.projectsDatatable.loading = false));
        }
    }

    onDatatableOptionsChanged(options: DataOptions): void {
        this.loadProjects(options);
    }

    deleteProject(project: IStimulusProjectsApiItem) {
        (this.$refs.confirmDeleteDialog as KomisDialog<string>)
            .open(this.$i18n.t("modals.confirm_delete.project", { name: project.name }).toString())
            .then(() => {
                deleteProject(project.id).then(() => {
                    this.loadProjects(this.projectsDatatable.options);
                });
            });
    }

    reopenProject(project: IStimulusProjectsApiItem) {
        setProjectState(project.id, EStimulusProjectStateCodes.Rozpracovany)
            .then((response) => {
                console.log(response);
            })
            .catch((err: any) => {
                console.error("setProjectState:", err);
                this.error = true;
            })
            .finally(() => this.loadProjects(this.projectsDatatable.options));
    }

    submitProject(project: IStimulusProjectsApiItem) {
        postProjectToSkcris(project.id)
            .then((response) => {
                console.log("Submit to SKCRIS:", response);
            })
            .catch((err: any) => {
                console.error("postProjectToSkcris:", err);
                this.error = true;
            })
            .finally(() => this.loadProjects(this.projectsDatatable.options));
    }

    canModify(item: IStimulusProjectsApiItem) {
        const isValidToModify =
            this.$route.params.organizationId &&
            item.state.code === EStimulusProjectStateCodes.Rozpracovany &&
            this.hasPermission;
        return isValidToModify;
    }

    getPdfLink(item: IStimulusProjectsApiItem) {
        window.open(getPdfLink(item.id, ETypeCodes.GrantsProjects));
    }

    getEditFormRoute(item: IStimulusProjectsApiItem) {
        window.location.assign(
            getNetgrifUrlByParams(
                "zobrazenie_formulara_rozpracovaneho_grant_projektu",
                "SKCRIS-033",
                { orgId: item.organization.id, grantId: this.$route.params.id, caseId: item.uri as string },
                this.$store.state.language.value,
            ) as string,
        );
    }
}

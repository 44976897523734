import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import SidebarLayout from "@/components/layouts/SidebarLayout.vue";
import { checkIsAdminOfOrganization, checkRole, USER_ROLE, VALIDATION_TYPE } from "@/helpers/user";
import Error403 from "@/views/Error403.vue";
import Error404 from "@/views/Error404.vue";
import StimulusListSidebar from "@/components/stimulus-list/StimulusListSidebar.vue";
import StimulusView from "@/components/stimulus-list/StimulusView.vue";
import ProjectsListSidebar from "@/components/projects/ProjectsListSidebar.vue";
import StimulusDetailSidebar from "@/components/stimulus-detail/StimulusDetailSidebar.vue";
import StimulusDetailView from "@/components/stimulus-detail/StimulusDetailView.vue";
import ProjectsListView from "@/components/projects/ProjectsListView.vue";
import DocumentsView from "@/components/documents/DocumentsView.vue";
import DocumentDetailView from "@/components/documents/DocumentDetailView.vue";
import DocumentDetailSidebar from "@/components/documents/DocumentDetailSidebar.vue";
import Navigation from "@/components/Navigation.vue";
import EvaluationsListView from "@/components/evaluations/EvaluationsListView.vue";
import EvaluationsListSidebar from "@/components/evaluations/EvaluationsListSidebar.vue";
import EvaluationsDetailView from "@/components/evaluations/EvaluationsDetailView.vue";
import EvaluationsNewView from "@/components/evaluations/EvaluationsNewView.vue";

Vue.use(VueRouter);

export enum RouteNames {
    Home = "HomeRoute",
    StimulusList = "StimulusListRoute",
    OrganizationStimulusList = "OrganizationStimulusListRoute",
    OrganizationStimulusDetail = "OrganizationStimulusDetailRoute",
    StimulusDetail = "StimulusDetailRoute",
    ProjectsList = "ProjectsListRoute",
    Documents = "DocumentsListRoute",
    DocumentDetail = "DocumentsDetailRoute",
    OrganizationDocumentDetail = "OrganizationDocumentDetailRoute",
    OrganizationDocuments = "OrganizationDocumentsListRoute",
    StimulusEvaluations = "StimulusEvaluationsRoute",
    StimulusEvaluationsDetail = "StimulusEvaluationsDetailRoute",
    StimulusEvaluationsNew = "StimulusEvaluationsNewRoute",
    StimulusEvaluationsDocuments = "StimulusEvaluationsDocumentsRoute",
    StimulusEvaluationsDocumentDetail = "StimulusEvaluationsDocumentDetailRoute",
    OrganizationStimulusEvaluations = "OrganizationStimulusEvaluationsRoute",
    AllEvaluations = "AllEvaluationsRoute",
    EvaluationsDetail = "EvaluationsDetailRoute",
    EvaluationsDocuments = "EvaluationsDocumentsListRoute",
    EvaluationsDocumentDetail = "EvaluationsDocumentDetailRoute",
    OrganizationStimulusProjectsList = "OrganizationStimulusProjectsListRoute",
    Error403 = "Error403Route",
    Error404 = "Error404Route",
}

const routes: Array<RouteConfig> = [
    {
        component: SidebarLayout,
        path: "/:language/dotacie",
        children: [
            {
                path: "",
                name: RouteNames.Home,
                redirect: {
                    name: RouteNames.StimulusList,
                },
            },
            {
                path: "ziadosti",
                name: RouteNames.StimulusList,
                components: {
                    default: StimulusView,
                    sidebar: StimulusListSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id",
                name: RouteNames.StimulusDetail,
                components: {
                    default: StimulusDetailView,
                    sidebar: StimulusDetailSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },

            {
                path: "ziadosti/:id/projekty",
                name: RouteNames.ProjectsList,
                components: {
                    default: ProjectsListView,
                    sidebar: ProjectsListSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/hodnotenia",
                name: RouteNames.StimulusEvaluations,
                components: {
                    default: EvaluationsListView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/hodnotenia/nova-uloha",
                name: RouteNames.StimulusEvaluationsNew,
                components: {
                    default: EvaluationsNewView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/hodnotenia/:evaluationId",
                name: RouteNames.StimulusEvaluationsDetail,
                components: {
                    default: EvaluationsDetailView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/hodnotenia/:evaluationId/dokumenty",
                name: RouteNames.StimulusEvaluationsDocuments,
                components: {
                    default: DocumentsView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/hodnotenia/:evaluationId/dokumenty/:documentId",
                name: RouteNames.StimulusEvaluationsDocumentDetail,
                components: {
                    default: DocumentDetailView,
                    sidebar: DocumentDetailSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/dokumenty",
                name: RouteNames.Documents,
                components: {
                    default: DocumentsView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "ziadosti/:id/dokumenty/:documentId",
                name: RouteNames.DocumentDetail,
                components: {
                    default: DocumentDetailView,
                    sidebar: DocumentDetailSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },

            {
                path: "hodnotenia",
                name: RouteNames.AllEvaluations,
                components: {
                    default: EvaluationsListView,
                    sidebar: EvaluationsListSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.Hodnotitel],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "hodnotenia/:evaluationId",
                name: RouteNames.EvaluationsDetail,
                components: {
                    default: EvaluationsDetailView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.Hodnotitel],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "hodnotenia/:evaluationId/dokumenty",
                name: RouteNames.EvaluationsDocuments,
                components: {
                    default: DocumentsView,
                    sidebar: Navigation,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.Hodnotitel],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            {
                path: "hodnotenia/:evaluationId/dokumenty/:documentId",
                name: RouteNames.EvaluationsDocumentDetail,
                components: {
                    default: DocumentDetailView,
                    sidebar: DocumentDetailSidebar,
                },
                meta: {
                    role: [USER_ROLE.SpravcaAgendyStimulyVav, USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.Hodnotitel],
                    roleValidationType: VALIDATION_TYPE.OneOf,
                },
            },
            /*
                === STRANKY ORGANIZACIE
             */
            {
                path: "ziadosti/organizacie/:organizationId",
                name: RouteNames.OrganizationStimulusList,
                components: {
                    default: StimulusView,
                    sidebar: StimulusListSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "organizationId",
                },
            },
            {
                path: "ziadosti/organizacie/:organizationId/detail/:id",
                name: RouteNames.OrganizationStimulusDetail,
                components: {
                    default: StimulusDetailView,
                    sidebar: StimulusDetailSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "organizationId",
                },
            },
            {
                path: "ziadosti/organizatie/:organizationId/detail/:id/dokumenty/:documentId",
                name: RouteNames.OrganizationDocumentDetail,
                components: {
                    default: DocumentDetailView,
                    sidebar: DocumentDetailSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "organizationId",
                },
            },
            {
                path: "ziadosti/organizacie/:organizationId/detail/:id/hodnotenia",
                name: RouteNames.OrganizationStimulusEvaluations,
                components: {
                    // TODO
                    default: ProjectsListView,
                    // TODO
                    sidebar: ProjectsListSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "id",
                },
            },
            {
                path: "ziadosti/organizacie/:organizationId/detail/:id/dokumenty",
                name: RouteNames.OrganizationDocuments,
                components: {
                    // TODO
                    default: DocumentsView,
                    // TODO
                    sidebar: ProjectsListSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "id",
                },
            },
            {
                path: "ziadosti/organizacie/:organizationId/detail/:id/projekty",
                name: RouteNames.OrganizationStimulusProjectsList,
                components: {
                    default: ProjectsListView,
                    sidebar: ProjectsListSidebar,
                },
                meta: {
                    isOrganizationAdmin: true,
                    routeIdParamName: "id",
                },
            },
            /*
                === ERROR PAGES
             */
            {
                path: "403",
                name: RouteNames.Error403,
                component: Error403,
            },
            {
                path: "*",
                name: RouteNames.Error404,
                component: Error404,
            },
        ],
    },

    /*{
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/!* webpackChunkName: "about" *!/ "../views/AboutView.vue"),
    },*/
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isAuthorized = await checkRole(to);
    const isAdminOfOrganization = await checkIsAdminOfOrganization(to);

    // is authorized || not authorized but is admin of org.
    if (isAuthorized || (isAdminOfOrganization !== null && !isAuthorized && isAdminOfOrganization)) {
        next();
    } else {
        next({ name: RouteNames.Error403, params: to.params });
    }
});

export default router;

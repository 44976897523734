import Vue from "vue";
import { AxiosResponse } from "axios";
import { api, ECategoryCode, ICategorySmRequestStatusResponse, IStimulusState } from "@/api/stimulus.api";

const evaluationsStates = Vue.observable({ states: [] as IStimulusState[] });

export enum EEvaluationStates {
    Vytvorena = "1201026001",
    Uzavreta = "1201026002",
    Zrusena = "1201026003",
}

let pendingPromise: Promise<any> | null = null;

export const getEvaluationsStates = async (): Promise<IStimulusState[]> => {
    if (!evaluationsStates.states.length) {
        pendingPromise = api
            .get(`/api/v1/grants/categories/${ECategoryCode.smReviewStatus}`) //
            .then((response: AxiosResponse<ICategorySmRequestStatusResponse>) => {
                evaluationsStates.states = response.data.data;
                return evaluationsStates.states;
            })
            .finally(() => (pendingPromise = null));
        return pendingPromise;
    } else {
        return evaluationsStates.states;
    }
};

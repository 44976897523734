



















import { Component } from "vue-property-decorator";
import { KomisButton, KomisContentHeader } from "@iway/spa-components";
import BaseView from "@/views/BaseView.vue";
import { RouteNames } from "@/router";
import StimulusTable from "@/components/stimulus-list/StimulusTable.vue";
import UploadDocument from "@/components/documents/UploadDocument.vue";
import DocumentsTable from "@/components/documents/DocumentsTable.vue";
import { userIsOrganizationAdmin } from "@/api/organization.api";
import { getStimulusDetail, IStimulus, getEvaluationsDetail, IEvaluationDetail, EStimulusStateCodes } from "@/api/stimulus.api";
import { EEvaluationStates } from "@/api/evaluations-states";
import { isOriginFromPersonalSpace } from "@/mixins/Utils";

@Component({
    components: { DocumentsTable, UploadDocument, StimulusTable, KomisContentHeader, KomisButton },
})
export default class DocumentsView extends BaseView {
    isAdminOfOrganization: boolean | null = null;

    stimulusDetail: IStimulus | false = false;

    evaluationDetail: IEvaluationDetail | false = false;

    async created() {
        if (this.organizationRoute && this.organizationId) {
            this.isAdminOfOrganization = await userIsOrganizationAdmin(this.organizationId).catch(() => false);
        }
    }

    get isOriginFromPersonalSpace(): boolean {
        return isOriginFromPersonalSpace();
    }

    get evaluationsRoute() {
        return this.$route.name === RouteNames.EvaluationsDocuments;
    }

    get stimulusEvaluationsRoute() {
        return this.$route.name === RouteNames.StimulusEvaluationsDocuments;
    }

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationDocuments;
    }

    get stimulusId(): string {
        return this.$route.params.id;
    }

    get organizationId(): string | null {
        return this.$route.params.organizationId || null;
    }

    get pageTitle() {
        if (this.organizationRoute) {
            return this.$i18n.t("organization.documents_list").toString();
        } else if (this.evaluationsRoute || this.stimulusEvaluationsRoute) {
            return this.$i18n.t("evaluations.documents").toString();
        } else {
            return this.$i18n.t("stimulus.documents").toString();
        }
    }

    get backButtonRoute() {
        let back: any = {};
        if (this.organizationRoute) {
            back = {
                name: RouteNames.OrganizationStimulusDetail,
                params: { id: this.$route.params.id, organizationId: this.$route.params.organizationId },
            };
        } else if (this.stimulusEvaluationsRoute) {
            back = {
                name: RouteNames.StimulusEvaluationsDetail,
                params: { id: this.$route.params.id, evaluationId: this.$route.params.evaluationId },
            };
        } else if (this.evaluationsRoute) {
            back = { name: RouteNames.EvaluationsDetail, params: { evaluationsId: this.$route.params.evaluationId } };
        } else {
            back = { name: RouteNames.StimulusDetail, params: { id: this.$route.params.id } };
        }

        return back;
    }

    get canUpload() {
        if (this.stimulusDetail) {
            return (
                this.isAdminOfOrganization &&
                this.isOriginFromPersonalSpace &&
                (this.stimulusDetail.state.code === EStimulusStateCodes.Rozpracovana ||
                    this.stimulusDetail.state.code === EStimulusStateCodes.VratenaNaDoplnenie)
            );
        } else if (this.evaluationDetail) {
            return this.evaluationDetail.state.code === EEvaluationStates.Vytvorena;
        } else return false;
    }

    mounted() {
        if (this.organizationRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.stimulus_list").toString(),
                to: { name: RouteNames.OrganizationStimulusList, params: { id: this.$route.params.id } },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("organization.documents_list").toString(),
                to: { name: RouteNames.OrganizationDocuments, params: { id: this.$route.params.id } },
                disabled: false,
                link: true,
                exact: true,
            });

            getStimulusDetail(this.stimulusId).then((stimulusDetail) => (this.stimulusDetail = stimulusDetail));
        } else if (this.evaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list").toString(),
                to: { name: RouteNames.AllEvaluations },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.documents").toString(),
                to: { name: RouteNames.Documents },
                disabled: false,
                link: true,
                exact: true,
            });

            getEvaluationsDetail(this.$route.params.evaluationId).then(
                (evaluationDetail) => (this.evaluationDetail = evaluationDetail),
            );
        } else if (this.stimulusEvaluationsRoute) {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list") as string,
                to: {
                    name: RouteNames.StimulusList,
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail") as string,
                to: {
                    name: RouteNames.StimulusDetail,
                    params: { id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.list") as string,
                to: {
                    name: RouteNames.StimulusEvaluations,
                    params: { id: this.$route.params.id },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.detail") as string,
                to: {
                    name: RouteNames.StimulusEvaluationsDetail,
                    params: { id: this.$route.params.id, evaluationId: this.$route.params.evaluationId },
                },
                disabled: false,
                link: true,
                exact: true,
            });

            this.breadcrumbItems.push({
                text: this.$i18n.t("evaluations.documents").toString(),
                disabled: true,
                link: true,
                exact: true,
            });

            getEvaluationsDetail(this.$route.params.evaluationId).then(
                (evaluationDetail) => (this.evaluationDetail = evaluationDetail),
            );
        } else {
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.list").toString(),
                to: { name: RouteNames.StimulusList },
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.detail").toString(),
                to: this.backButtonRoute,
                disabled: false,
                link: true,
                exact: true,
            });
            this.breadcrumbItems.push({
                text: this.$i18n.t("stimulus.documents").toString(),
                to: { name: RouteNames.Documents },
                disabled: false,
                link: true,
                exact: true,
            });
        }
    }

    onFileUploaded(file) {
        console.info("onFileUploaded", file, "proceed table reload");
        (this.$refs.documentsTable as DocumentsTable).reload();
    }
}

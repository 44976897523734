


























































import { Component, Vue, Watch } from "vue-property-decorator";
import { KomisButton, KomisDateField, KomisSelect, KomisSidebarHeader, KomisTextField } from "@iway/spa-components";
import Navigation from "@/components/Navigation.vue";
import {
    initialEvaluationsFilter,
    IEvaluationsListFilterOptions,
    evaluationsFilter,
} from "@/components/evaluations/evaluationsFilter";
import { EStimulusStateCodes, IStimulusState, IStimulusEvaluator } from "@/api/stimulus.api";
import { getEvaluationsStates } from "@/api/evaluations-states";
import { getStimulusEvaluators } from "@/api/stimulus-evaluators";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import { castArray, cloneDeep, isEqual } from "lodash";

@Component({
    components: {
        Navigation,
        KomisTextField,
        KomisButton,

        KomisSidebarHeader,
        KomisDateField,
        KomisSelect,
    },
})
export default class EvaluationsListSidebar extends Vue {
    filter: IEvaluationsListFilterOptions = initialEvaluationsFilter();

    // stimulus state filter select
    statesLoading = true;
    evaluatorsLoading = true;
    allStates: IStimulusState[] = [];
    allEvaluators: IStimulusEvaluator[] = [];

    get allowFilter(): boolean {
        return userHasRole([USER_ROLE.ClenKomisieStimulyVav, USER_ROLE.SpravcaAgendyStimulyVav]);
    }

    get disableStateSelect(): boolean {
        // return userHasRole(USER_ROLE.ClenKomisieStimulyVav);
        return false;
    }
    // TODO treba ziskat id hodnotitela a presetnut ho do flitra a zaroven zakazat select
    get disableEvaluatorSelect(): boolean {
        return userHasRole(USER_ROLE.Hodnotitel) && !userHasRole(USER_ROLE.SpravcaAgendyStimulyVav);
    }

    get states(): IStimulusState[] {
        // clenKomisie? Show only state "Podana"
        // if (userHasRole(USER_ROLE.ClenKomisieStimulyVav)) {
        //     return castArray(this.allStates.find((state) => state.code === EStimulusStateCodes.Podana)) || [];
        // }
        return this.allStates;
    }

    get evaluators(): IStimulusEvaluator[] {
        return this.allEvaluators;
    }

    get evaluationsFilter() {
        return evaluationsFilter;
    }

    created() {
        // load stimulus states
        this.statesLoading = true;
        getEvaluationsStates()
            .then((states) => {
                this.allStates = states;
            })
            .finally(() => (this.statesLoading = false));

        getStimulusEvaluators()
            .then((evaluators) => {
                console.log("Evaluators:", evaluators);
                this.allEvaluators = evaluators;
            })
            .finally(() => (this.evaluatorsLoading = false));
    }

    @Watch("evaluationsFilter", { deep: true })
    onStimulusFilterChanged(newFilter: IEvaluationsListFilterOptions) {
        if (!isEqual(cloneDeep(this.filter), cloneDeep(newFilter))) {
            this.filter = cloneDeep(newFilter);
        }
    }

    async mounted() {
        await this.$nextTick();
        console.log(
            !isEqual(cloneDeep(this.filter), cloneDeep(evaluationsFilter)),
            cloneDeep(this.filter),
            cloneDeep(evaluationsFilter),
        );
    }

    filterResults() {
        // Sync evaluationsFilter with local component filter
        evaluationsFilter.orgname = this.filter.orgname;
        evaluationsFilter.evaluatorname = this.filter.evaluatorname;
        evaluationsFilter.state = this.filter.state;
        evaluationsFilter.datefrom = this.filter.datefrom;
        evaluationsFilter.dateto = this.filter.dateto;
    }

    clearFilter() {
        this.filter = initialEvaluationsFilter();
        this.$nextTick(() => this.filterResults());
    }
}













































import { Component, Prop, Vue } from "vue-property-decorator";
import { getStimulusDetail, IStimulus, EStimulusStateCodes } from "@/api/stimulus.api";
import { KomisButton, KomisKeyValueList } from "@iway/spa-components";
import { RouteNames } from "@/router";
import { USER_ROLE, userHasRole } from "@/helpers/user";
import StimulusStateChange from "@/components/stimulus-detail/StimulusStateChange.vue";
import StateHistoryTable from "@/components/stimulus-detail/StateHistoryTable.vue";
import { ETypeCodes, getPdfLink } from "@/helpers/linkBuilder";
import { getNetgrifUrlByParams } from "@iway/spa-components/lib";

@Component({
    components: { StimulusStateChange, KomisKeyValueList, KomisButton, StateHistoryTable },
})
export default class StimulusDetail extends Vue {
    @Prop({ required: true, type: String }) stimulusId!: string;

    RouteNames = RouteNames;
    stimulusDetail: IStimulus | false = false;
    loading = true;
    error = false;
    historyEnabled = false;
    componentKey = 0;

    get organizationRoute() {
        return this.$route.name === RouteNames.OrganizationStimulusDetail;
    }

    get readonly() {
        return !userHasRole(USER_ROLE.SpravcaAgendyStimulyVav);
    }

    get projectsListRoute() {
        if (this.organizationRoute) {
            return {
                name: RouteNames.OrganizationStimulusProjectsList,
                params: { organizationId: this.$route.params.organizationId, id: this.stimulusId },
            };
        } else {
            return { name: RouteNames.ProjectsList, params: { id: this.stimulusId } };
        }
    }

    get documentsListRoute() {
        if (this.organizationRoute) {
            return {
                name: RouteNames.OrganizationDocuments,
                params: { organizationId: this.$route.params.organizationId, id: this.stimulusId },
            };
        } else {
            return { name: RouteNames.Documents, params: { id: this.stimulusId } };
        }
    }

    get detailInfoItems() {
        if (!this.stimulusDetail) {
            return [];
        }

        return [
            { key: this.$i18n.t("detail.organization").toString(), value: this.stimulusDetail.organization.name },
            { key: this.$i18n.t("detail.code").toString(), value: this.stimulusDetail.code },
            { key: this.$i18n.t("stimulus.datatable.create_date").toString(), value: this.stimulusDetail.createDate },
            { key: this.$i18n.t("stimulus.datatable.send_date").toString(), value: this.stimulusDetail.sendDate },
            { key: this.$i18n.t("stimulus.datatable.state").toString(), value: this.stimulusDetail.state.name },
            {
                key: this.$i18n.t("detail.current_version").toString(),
                value: this.$i18n.t("stimulus.datatable.form"),
                to: this.getFormLink(this.stimulusDetail),
                externalLink: true,
            },
        ];
    }

    async created() {
        this.loadData();
    }

    getFormLink(detailItem: IStimulus) {
        if (
            this.$route.params.organizationId &&
            (detailItem.state.code === EStimulusStateCodes.Rozpracovana ||
                detailItem.state.code === EStimulusStateCodes.VratenaNaDoplnenie)
        ) {
            return getNetgrifUrlByParams(
                "zobrazenie_formulara_rozpracovanej_ziadosti",
                "SKCRIS-032",
                { orgId: detailItem.organization.id, caseId: detailItem.uri as string },
                this.$store.state.language.value,
            ) as string;
        } else {
            return getPdfLink(detailItem.formId, ETypeCodes.Grants);
        }
    }

    loadData() {
        this.loading = true;
        getStimulusDetail(this.stimulusId)
            .then((stimulusDetail) => (this.stimulusDetail = stimulusDetail))
            .catch(() => (this.error = true))
            .finally(() => {
                this.loading = false;
                this.historyEnabled = true;
                console.log("History Loaded..");
                this.componentKey += 1;
            });
    }
}

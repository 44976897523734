import { render, staticRenderFns } from "./StimulusListSidebar.vue?vue&type=template&id=23ca6a82&scoped=true&"
import script from "./StimulusListSidebar.vue?vue&type=script&lang=ts&"
export * from "./StimulusListSidebar.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23ca6a82",
  null
  
)

export default component.exports